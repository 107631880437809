@charset "UTF-8";

@font-face {
  font-family: 'SKTTmap300';
  font-display: swap;
  src: url('../../assets/fonts/SKTTmap300.woff') format('woff'),
    url('../../assets/fonts/SKTTmap300.ttf') format('truetype');
}
@font-face {
  font-family: 'SKTTmap500';
  font-display: swap;
  src: url('../../assets/fonts/SKTTmap500.woff') format('woff'),
    url('../../assets/fonts/SKTTmap500.ttf') format('truetype');
}
@font-face {
  font-family: 'SKTTmap700';
  font-display: swap;
  src: url('../../assets/fonts/SKTTmap700.woff') format('woff'),
    url('../../assets/fonts/SKTTmap700.ttf') format('truetype');
}
