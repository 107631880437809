@charset "UTF-8";

.swiper {
  &-container {
    margin-bottom: 28px;
  }
  &-slide {
    width: 100%;
    height: 100%;
    position: relative;
    flex-shrink: 0;
    text-align: center;
  }
  &-banner {
    width: 100%;
    max-width: 360px;
  }
  &-pagination {
    @include position(null, 0, 6px, 0);
    overflow: hidden;
    text-align: center;
    line-height: 0;

    &-bullet {
      display: inline-block;
      width: 6px;
      height: 6px;
      margin: 0 3px;
      border-radius: 50%;
      background: #6e7685;
      opacity: .2;
      vertical-align: top;
      font-size: 1px;
      color: transparent;
      line-height: 100px;
    }
    &-active {
      opacity: 1;
    }
  }
}

.voice_type {
  margin: 18px 0 0 20px;

  &_title {
    margin-bottom: 2px;
    color: $C-black;
    line-height: 24px;
    font-size: 22px;
    font-family: $F-family-medium;
  }
  &_item {
    border-bottom: 1px solid #f2f2f2;

    .radio_area {
      height: 67px;

      .label {
        display: block;

        &::after {
          @include position(10px, 10px, null, null);
        }
        .text {
          color: $C-black;
          line-height: 67px;
          font-size: 19px;
        }
      }
    }
  }
}

.celeb_voice {
  margin-left: 20px;

  &.end {
    .celeb_voice_title {
      position: relative;
      margin: 0;
      padding: 25px 0 26px;
      line-height: 22px;
      font-size: 17px;

      &::after {
        @include position(50%, 20px);
        content: '';
        background: url("../../assets/images/icon-fold-gray.svg") no-repeat;
        background-size: 100% 100%;
        width: 18px;
        height: 12px;
        margin-top: -9px;
      }
      &.unfold {
        &::after {
          transform: rotate(180deg);
        }
      }
    }
    .celeb_voice_list {
      margin-top: -26px;
    }
    .celeb_voice_thumb {
      .thumb {
        &::after {
          display: none;
        }
      }
    }
  }
  &_title {
    margin: 50px 0 2px;
    color: $C-black;
    line-height: 24px;
    font-size: 22px;
    font-family: $F-family-medium;
  }
  &_item {
    position: relative;
    min-height: 124px;
    padding: 25px 20px 25px 90px;
    border-bottom: 1px solid #f2f2f2;

    .btn_use,
    .btn_buy,
    .btn_free {
      @include position(47px, 20px);
      overflow: hidden;
      width: 79px;
      height: 34px;
      background: url("../../assets/images/btn-buy.svg") no-repeat;
      font-size: 1px;
      text-indent: -300px;
    }
    .btn_free {
      background: url("../../assets/images/btn-experience.svg") no-repeat;
    }
    .btn_use {
      background: url("../../assets/images/btn-use.svg") no-repeat;
    }
    .radio_area {
      @include position(40px, 10px);
    }
  }
  &_thumb {
    @include position(27px, null, null, 0);
    width: 74px;
    height: 74px;
    background: url("../../assets/images/thumb_default.svg") no-repeat;
    background-size: 100% 100%;

    .thumb {
      &::after {
        @include position(null, -12px, -12px, null, 100);
        content: '';
        width: 46px;
        height: 46px;
        background: url("../../assets/images/btn-play.svg") no-repeat;
      }
      &.tts {
        &::after {
          background-image: url("../../assets/images/btn-play-on.svg");
        }
        .animate {
          display: block;
        }
      }
      &.new {
        &::before {
          @include position(2px, null, null, 2px, 100);
          content: '';
          width: 15px;
          height: 15px;
          background: url("../../assets/images/icon-new.svg") no-repeat;
        }
      }
      .img {
        overflow: hidden;
        border-radius: 50%;
      }
    }
    .animate {
      display: none;
      @include position(0, 0, 0, 0);
      overflow: hidden;
      border-radius: 50%;
      background-color: rgba(0, 0, 0, 0.55);

      .ani {
        @include position(0, 0, 0, 0);
        width: 17px;
        height: 12px;
        margin: auto;
      }
    }
  }
  &_info {
    display: block;
    text-align: left;
    width: 100%;

    &.selected {
      .title,
      .desc {
        width: calc(100% - 48px);
      }
    }

    &.ended {
      .title,
      .desc {
        width: 100%;
      }
    }

    .title {
      display: inline-flex;
      flex-direction: row;
      width: calc(100% - 114px);

      .name {
        overflow: hidden;
        max-width: 100%;
        white-space: nowrap;
        text-overflow: ellipsis;
        color: $C-black;
        line-height: 25px;
        font-size: 19px;
      }
      .icon {
        overflow: hidden;
        height: 21px;
        margin: 2px 0 0 4px;
        flex-shrink: 0;
        color: transparent;
        text-indent: -100px;
        font-size: 1px;

        &.pick {
          width: 56px;
          background-image: url("../../assets/images/icon-tmappick.svg");
        }
        &.event {
          width: 40px;
          background-image: url("../../assets/images/icon-event.svg");
        }
        &.dday {
          padding: 0 4px;
          border-radius: 2px;
          text-indent: 0;
          background: #eff4fe;
          color: $C-primary;
          line-height: 21px;
          font-size: 11px;
          font-family: $F-family-bold;
        }
      }
    }
    .desc {
      display: -webkit-box;
      overflow: hidden;
      width: calc(100% - 114px);
      max-height: 40px;
      margin: 4px 0 8px;
      -webkit-line-clamp: 2;
      -webkit-box-orient: vertical;
      text-overflow: ellipsis;
      color: $C-font-blurry;
      font-size: 15px;
      line-height: 20px;
    }
    .price {
      color: $C-black;
      line-height: 21px;
      font-size: $F-size;
      font-family: $F-family-medium;
    }
    .sale {
      margin-bottom: 3px;
      line-height: 17px;
      font-size: 13px;

      .won {
        color: $C-font-gray;
        text-decoration: line-through;
      }
      .rate {
        margin-left: 5px;
        color: $C-point;
        font-family: $F-family-bold;
      }
    }
    .period {
      color: $C-primary;
      font-size: $F-size;
      line-height: 21px;
    }
    .end {
      color: $C-font-blurry;
      line-height: 21px;
      font-size: $F-size;
      font-family: $F-family-medium;
    }
  }
}

.voice_detail {
  padding: 0 20px 190px;

  &.purchase_complete {
    padding-bottom: 130px;
  }
  &_thumb {
    text-align: center;

    .thumb {
      position: relative;
      width: 170px;
      height: 170px;
      background: url("../../assets/images/thumb_default.svg") no-repeat;
      background-size: 100% 100%;

      &::after {
        @include position(null, -20px, -20px, null);
        content: '';
        width: 94px;
        height: 94px;
        background: url("../../assets/images/btn-play.svg") no-repeat;
        background-size: 100% 100%;
      }
      &.tts {
        &::after {
          background-image: url("../../assets/images/btn-play-on.svg");
        }
        .animate {
          display: block;
        }
      }
      .img {
        overflow: hidden;
        border-radius: 50%;
      }
    }
    .animate {
      display: none;
      @include position(0, 0, 0, 0);
      overflow: hidden;
      border-radius: 50%;
      background-color: rgba(0, 0, 0, 0.55);

      .ani {
        @include position(0, 0, 0, 0);
        width: 37px;
        height: 29px;
        margin: auto;
      }
    }
  }
  &_info {
    .title {
      margin-top: 20px;
      color: $C-black;
      line-height: 34px;
      font-size: 26px;
      font-family: $F-family-bold;
    }
    .desc {
      margin-top: 9px;
      padding: 0 50px;
      color: $C-font-blurry;
      line-height: 21px;
      font-size: 15px;
    }
  }
  &_list {
    margin-top: 20px;
    padding: 26px 0;
    border: 1px solid #f2f2f2;
    border-width: 1px 0;
  }
  &_item {
    overflow: hidden;
    padding-top: 9px;

    &:first-child {
      padding-top: 0;
    }
    .label {
      float: left;
      width: 90px;
      font-family: $base-font-family;
    }
    .cont {
      overflow: hidden;
    }
    .text,
    .price,
    .number,
    .period,
    .datetime {
      margin-top: 2px;
      color: $C-black;
      line-height: 22px;
      font-size: 17px;
      font-family: $F-family-medium;
    }
    .price {
      display: inline-block;
      margin: 0 8px 0 0;
    }
    .number {
      font-family: $base-font-family;
    }
    .period,
    .datetime {
      color: $C-primary;
      line-height: 20px;
      font-size: 15px;
    }
    .datetime {
      opacity: .7;
      color: $C-font-blurry;
      font-family: $base-font-family;
    }
    .sale {
      display: inline-block;
      font-size: 15px;

      .won {
        margin-right: 8px;
        color: $C-font-gray;
        text-decoration: line-through;
        font-family: $base-font-family;
      }
      .rate {
        color: $C-point;
        font-family: $F-family-bold;
      }
    }
  }
  &_notice {
    margin-top: 10px;

    .title {
      position: relative;
      padding: 16px 0;
      color: $C-black;
      line-height: 22px;
      font-size: 17px;
      font-family: $F-family-medium;

      &::after {
        @include position(50%, 0);
        content: '';
        width: 18px;
        height: 12px;
        margin-top: -6px;
        background: url("../../assets/images/icon-fold-gray.svg") no-repeat;
        background-size: 100% 100%;
      }
      &.unfold {
        &::after {
          transform: rotate(-180deg);
        }
      }
    }
    .please {
      display: inline-block;
      background: rgba(229, 85, 69, .08);
      color: $C-point;
      line-height: 21px;
      font-size: $F-size;
      font-family: $F-family-medium;
    }
  }
  &_desc {
    font-size: $F-size;

    .tit {
      margin-top: 26px;
      color: $C-black;
      line-height: 21px;
    }
    .cont {
      margin-top: 9px;
      line-height: 24px;
      color: $C-font-blurry;
      word-break: break-word;
    }
    .link {
      display: inline-block;
      position: relative;
      color: $C-primary;
      text-decoration: underline;
      text-underline-offset: 7px;
    }
  }
  &_footer {
    position: fixed;
    right: 0;
    bottom: 0;
    left: 0;
    z-index: 100;
    padding: 0 20px 20px;
    background: #fff;

    .btn_buy {
      width: 100%;
      height: 52px;
      border-radius: 5px;
      background: $C-primary;
      color: $C-white;
      font-size: 17px;

      &:disabled {
        background: #e1e1e1;
      }
    }
    .btn_coupon {
      width: 100%;
      height: 60px;
      margin: 20px 0 -20px;
      border-top: 1px solid #f2f2f2;
      line-height: 60px;
      font-size: $F-size;

      &::after {
        display: inline-block;
        content: '';
        width: 20px;
        height: 20px;
        margin-top: 19px;
        background: url("../../assets/images/group.svg") no-repeat 0 0 / 100% 100%;
        vertical-align: top;
      }
    }
  }
}

.red_app {
  margin-top: 47px;
  text-align: center;

  &_info {
    padding: 0 20px;

    .desc {
      margin: 20px 0 50px;
      color: $C-font-blurry;
      line-height: 23px;
      font-size: 16px;

      .line_break {
        display: inline-block;
      }
    }
    .img {
      background: #f6f6f7;

      img {
        width: 100%;
      }
    }
  }
  &_update {
    padding: 0 20px;

    .desc {
      margin: 40px 20px 24px;
      color: $C-black;
      line-height: 23px;
      font-size: $F-size;

      .point {
        display: inline-block;
        color: $C-primary;
        font-family: $F-family-medium;

        &::before {
          content: '';
          display: inline-block;
          width: 15px;
          height: 17px;
          margin: 4px 4px 0 0;
          background: url('../../assets/images/google-play-logo@2x.png') no-repeat;
          background-size: 100%;
          vertical-align: top;
        }
      }
      .line_break {
        display: inline-block;
      }
    }
    .btn_dummy {
      display: block;
      visibility: hidden;
      height: 52px;

      &.hide {
        height: 0;
      }
    }
    .btn_update {
      display: block;
      height: 52px;
      border-radius: 5px;
      box-shadow: 0 4px 8px 0 rgba(54, 115, 238, 0.3);
      background: $C-primary;
      color: $C-white;
      line-height: 52px;
      font-size: 17px;

      &.fixed {
        @include position(null, 20px, 20px, 20px, 1000, fixed);
      }
    }
  }
  &_payment {
    margin-top: 40px;
    padding: 36px 20px 36px 24px;
    background: #3c414b;
    text-align: left;

    .item {
      position: relative;
      padding-left: 12px;
      color: #ccc;
      line-height: 24px;
      font-size: $F-size-normal;

      &::before {
        @include position(10px, null, null, 0);
        content: '';
        overflow: hidden;
        width: 4px;
        height: 4px;
        border-radius: 50%;
        background: #ccc;
      }
    }
  }
}

.voice_coupon {
  width: 100%;
  padding: 0 0 0 20px;

  .title,
  .btn_confirm {
    font-family: $F-family-medium;
    line-height: 1.2;
  }

  .title {
    width: 74px;
    height: 24px;
    margin: 24px 266px 20px 0;
    font-size: 20px;
    color: $C-black;
  }
  &_input {
    height: 52px;
    margin: 9px 0 0;
    border-bottom: 1px solid #e0e0e0;
    position: relative;

    .ipt {
      width: calc(100% - 110px);
      height: 22px;
      margin: 15px 0;
      font-size: $F-size-big;
      font-family: $base-font-family;
      line-height: 1.22;
      color: $C-black;
      border: 0;

      &::placeholder {
        color: $C-font-blurry;
      }
      &:focus {
        caret-color: #0064ff;
      }
    }
    .btn_confirm {
      @include position(9px, 20px);
      width: 60px;
      height: 34px;
      border-radius: 17px;
      background-color: $C-primary;
      font-size: $F-size-normal;
      color: $C-white;

      &:disabled {
        background: #dddddd;
      }
    }
  }
  &_error {
    margin: 20px 20px 20px 0;
    font-family: $F-family-medium;
    font-size: $F-size;
    line-height: 1.31;
    color: #e55545;
  }
  &_period {
    text-align: center;
    width: 100%;
    height: 21px;
    margin: 20px 0 38px 0;
    font-family: $F-family-medium;
    font-size: $F-size;
    line-height: 1.31;
    color: $C-primary;
  }
  &_empty {
    width: 100%;
    text-align: center;
    display: inline-block;
    margin: 48px 0;

    .img {
      width: 120px;
      height: 120px;
      margin: 0 0 12px 0;
      object-fit: contain;
    }

    .title {
      height: 52px;
      font-family: $base-font-family;
      font-size: $F-size-big;
      line-height: 1.44;
      letter-spacing: -1px;
      text-align: center;
      color: $C-font-blurry;
    }
  }
  &_notice {
    margin-top: auto;

    .voice_detail_notice {
      margin: 10px 20px 0 20px;
    }
  }
  &_desc {
    color: $C-font-blurry;
    word-break: break-word;
    margin-bottom: 60px;
    font-size: $F-size;
  }
}

@media (min-width: 360px) {
  .app_red_info {
    .img {
      img {
        width: auto;
      }
    }
  }
}

@media (orientation: landscape), (min-width: 591px) {
  .swiper-container {
    display: none;
  }
  .voice_detail {
    &_thumb {
      .thumb {
        width: 136px;
        height: 136px;

        &::after {
          @include position(null, -16px, -16px, null);
          content: '';
          width: 76px;
          height: 76px;
          background: url("../../assets/images/btn-play.svg") no-repeat;
          background-size: 100% 100%;
        }
        .img {
          width: 136px;
          height: 136px;
        }
      }
    }
  }
}