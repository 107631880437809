@charset "UTF-8";

.app {
  height: 100%;
}
.container {
  display: flex;
  flex-direction: column;
  min-height: 100%;
}
// header
.header {
  @include position(0, 0, null, 0, 100, fixed);
  height: 56px;

  &.detail {
    .title {
      color: transparent;
      font-size: 1px;
      line-height: 200px;
    }
    .thumb {
      display: block;
    }
  }
  .title {
    color: $C-black;
    line-height: 56px;
    text-align: center;
    font-family: $F-family-medium;
    font-size: $F-size-big;
  }
  .thumb {
    @include position(10px, null, null, 50%);
    margin-left: -18px;
    opacity: 0;

    img {
      overflow: hidden;
      border-radius: 50%;
    }
  }
  .btn_coupon_back,
  .btn_back {
    width: 52px;
    background-size: 100% 100%;
    background-position: 0 0;
    background-repeat: no-repeat;
    color: transparent;
    text-indent: -100px;
    font-size: 1px;
  }
  .btn_back {
    @include position(0, null, 0, 0);
    overflow: hidden;
    background-image: url("../../assets/images/icon-arrow.svg");
  }
  .bg {
    @include position(0, 0, 0, 0);
    background: $C-white;
    opacity: 0;
    z-index: -1;

    &::after {
      @include position(null, 0, -15px, 0);
      content: '';
      height: 15px;
      background-image: linear-gradient(to bottom, rgba(0, 0, 0, 0.08), rgba(216, 216, 216, 0));
    }
  }
  .btn_circle {
    @include position(0, 0, 0, calc(100% - 51px));
    width: 36px;
    margin: 10px 8px;
    padding: 0 9px;
    background-color: $C-primary;
    border-radius: 100%;
    font-family: $F-family-medium;
    font-size: $base-font-size;
    line-height: 1.2;
    letter-spacing: -0.46px;
    color: $C-white;
  }
  .btn_coupon_back {
    @include position(0, 0, 0);
    left: auto;
    background-image: url("../../assets/images/icon-close.svg");
  }
}

// contents
.content {
  padding-top: 56px;
}

// footer
.footer {
  margin-top: auto;
  padding: 30px 20px 80px;
  background: #f0f0f0;

  .customer {
    overflow: hidden;

    &_item {
      position: relative;
      float: left;
      color: $C-font-blurry;
      line-height: 18px;
      font-size: $F-size-normal;

      &:last-child {
        margin-left: 9px;
        padding-left: 10px;

        &::before {
          @include position(3px, null, null, 0);
          content: '';
          opacity: 0.2;
          width: 1px;
          height: 12px;
          background: #979797;
        }
      }
    }
  }
  .company {
    margin-top: 7px;

    &_info {
      &_item {
        overflow: hidden;
        color: $C-font-gray;
        line-height: 22px;
        font-size: $F-size-small;

        .tit {
          float: left;
          margin-right: 10px;
          color: $C-font-blurry;
        }
        .desc {
          overflow: hidden;
          word-break: keep-all;
        }
      }
    }

    .btn_company_info {
      display: inline-block;
      position: relative;
      padding-right: 17px;
      color: $C-font-gray;
      line-height: 18px;
      font-size: $F-size-normal;

      &::after {
        @include position(50%, 0, null, null);
        content: '';
        background: url("../../assets/images/icon-fold-gray.svg") no-repeat 0 0;
        background-size: 100% 100%;
        width: 13px;
        height: 8px;
        margin-top: -4px;
      }
      &.show{
        &::after {
          transform: rotate(180deg);
        }
      }
    }
  }
}